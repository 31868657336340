import React, { useContext } from "react"
import RoughEdge from "./rough-edge"
import styled, { ThemeContext } from "styled-components"

const OpeningBannerStyled = styled.div`
  background-color: ${(p) => p.theme.DarkBlue};
  position: relative;
  z-index: 1;

  > .inner {
    padding: 45px 0;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }

  h2 {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.White};
    text-transform: uppercase;
    font-size: 6em;
    margin: 10px 0;
    line-height: 1em;
    letter-spacing: 2px;
    text-align: center;
    font-weight: 800;
    letter-spacing: 2px;
  }

  h3 {
    text-align: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.MedABlue};
    margin: 10px;
    font-size: 2.4em;
    font-family: "Montserrat";
    letter-spacing: 1.2px;
  }

  p {
    font-size: 1.4em;
    text-align: center;
    color: ${(p) => p.theme.White};
    margin: 0px auto;
    max-width: 830px;
  }
`

const OpeningBanner = ({ children }) => {
  const theme = useContext(ThemeContext)
  return (
    <OpeningBannerStyled>
      <RoughEdge className="top" color={theme.DarkBlue} />
      {children}
      <RoughEdge className="bottom" color={theme.DarkBlue} />
    </OpeningBannerStyled>
  )
}

export default OpeningBanner
