import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import ResponsiveSpacing from "../components/responsive-spacing"
import HeroArea from "../components/hero"
import Center from "../components/center"
import Feature from "../components/feature"
import Carousel from "../components/carousel"
import SEO from "../components/seo"
import BottomColorNav from "../components/bottom-color-nav"
import Button from "../components/button"
import OpeningBanner from "../components/opening-banner"
import Heading from "../components/heading"
import Timeline from "../components/about-us/timeline"
import FeaturesSlider from "../components/about-us/features-slider"

const AboutUsStyled = styled.div`
  .timelineCont {
    background-color: ${(p) => p.theme.BGBlue};
    padding: 50px 0;
  }
`

const FeaturesStyled = styled.div``

const AboutUs = ({ fields }) => {
  const { timeline, opening, features, bottom_feature, carousel } = fields
  return (
    <AboutUsStyled>
      <HeroArea fields={fields} />
      <OpeningBanner>
        <div className="inner">
          <ResponsiveSpacing>
            <h2
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: opening.headline,
              }}
            />
            <h3
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: opening.sub_heading,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: opening.statement,
              }}
            />
          </ResponsiveSpacing>
        </div>
      </OpeningBanner>
      <FeaturesSlider features={features} />
      <div className="timelineCont">
        <Heading>
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: timeline.headline,
            }}
          />
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: timeline.sub_heading,
            }}
          />
          <p>{timeline.summary}</p>
        </Heading>
        <Timeline fields={fields.timeline} />
      </div>
      <ResponsiveSpacing>
        <Feature {...bottom_feature} align="right" margin="150px auto" />
        <Heading>
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: carousel.headline,
            }}
          />
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: carousel.sub_heading,
            }}
          />
        </Heading>
      </ResponsiveSpacing>
      <Carousel />
      <Center margin="0 0 150px 0" mobileMargin="0 0 50px 0">
        <Button to={"/flavors"}>{carousel.button_label}</Button>
      </Center>
    </AboutUsStyled>
  )
}

const AboutUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "about-us-page" } }) {
        edges {
          node {
            acf {
              hero_area {
                top_line
                headline
                top_line_color
                headline_color
                wave_color
                background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              opening {
                headline
                sub_heading
                statement
              }
              features {
                headline
                sub_heading
                summary
                wave_color
                cta_link
                cta_label
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              timeline {
                headline
                sub_heading
                summary
                milestones {
                  year
                  blurb
                  image {
                    localFile {
                      childImageSharp {
                        fixed(height: 100) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
              bottom_feature {
                headline
                sub_heading
                summary
                wave_color
                button_label
                button_link
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              carousel {
                headline
                sub_heading
                button_label
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <AboutUs fields={fields} />
      <BottomColorNav />
    </Layout>
  )
}

export default AboutUsPage
