import React, { useState, useRef } from "react"
import styled from "styled-components"
import Slider from "../slider"
import SVG from "../svg"
import Feature from "../feature"

const FeaturesSliderStyled = styled.div`
  transition: padding 600ms;
  position: relative;
  padding: 0;
  width: 100%;

  .swave {
    position: absolute;
    top: 30%;
    z-index: 2;
    left: 50%;
    transform: scaleX(-1);
    margin-left: -590px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 1450px) {
    padding: 0px 50px;
  }
  @media (max-width: 768px) {
    padding: 0 50px;
    margin: 0;
  }
  @media (max-width: 580px) {
    padding: 0px 10px;
  }

  .slick-prev {
    color: ${(p) => p.theme.DarkBlue};
  }
  .slick-prev {
    color: ${(p) => p.theme.DarkBlue};
  }
  .slide-arrow {
    position: relative;
  }
  .slick-dots {
    max-width: 980px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    bottom: 135px;
    margin: 0 auto;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      text-align: center;
      bottom: 25px;
    }

    button:before {
      font-size: 40px;
    }

    ul {
      padding: 0;
      width: 450px;
      margin: 0;
      height: 14px;
    }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 140px;
      margin: 0 auto;
    }

    p {
      margin-left: 80px;
      width: 450px;
      padding: 20px 0;
    }
    @media (max-width: 768px) {
      p,
      ul {
        width: 100%;
        margin: 0;
      }
    }
  }
`

const FeaturesSlider = ({ features }) => {
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const slides = features.map((feature, i) => (
    <Feature
      {...feature}
      eagerLoadImage={true}
      key={i}
      no_wave={true}
      align="left"
      margin="150px auto"
    />
  ))
  const appendDots = (dots, sliderRef) => {
    return (
      <div className="dots">
        <ul>{dots}</ul>
        <p className="pagination">
          {currentSlide + 1}&nbsp;of&nbsp;{dots.length}
        </p>
      </div>
    )
  }

  return (
    <FeaturesSliderStyled>
      <SVG name="SWave" className="swave" color={features[0].wave_color} />
      <Slider
        ref={(slider) => {
          return (sliderRef.current = slider)
        }}
        settings={{
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          slidesToShow: 1,
          dots: true,
          speed: 1000,
          responsive: [],
          afterChange: (next) => {
            setCurrentSlide(next)
          },
          appendDots: (dots) => appendDots(dots, sliderRef),
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        }}
      >
        {slides}
      </Slider>
    </FeaturesSliderStyled>
  )
}

export default FeaturesSlider

const NextArrowStyled = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 152px;
  right: 50%;
  margin-right: -255px;
  cursor: pointer;
  z-index: 3;

  .nextArrow {
    color: ${(p) => p.theme.DarkBlue};
    height: 20px;
  }

  @media (max-width: 768px) {
    bottom: 42px;
    margin-right: -30px;
  }
`

const NextArrow = ({ children, className, onClick }) => {
  return (
    <NextArrowStyled>
      <div className={className} onClick={onClick}>
        <SVG name="Arrow" className="nextArrow" />
      </div>
    </NextArrowStyled>
  )
}

const PrevArrowStyled = styled.div`
  color: ${(p) => p.theme.DarkBlue};
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 152px;
  right: 50%;
  margin-right: -230px;
  cursor: pointer;
  z-index: 3;

  .prevArrow {
    color: ${(p) => p.theme.DarkBlue};
    height: 20px;
    transform: scaleX(-1);
  }

  @media (max-width: 768px) {
    left: 50%;
    right: auto;
    margin-left: -26px;
    bottom: 42px;
  }
`

const PrevArrow = ({ children, className, onClick }) => {
  return (
    <PrevArrowStyled onClick={onClick}>
      <div className={className}>
        <SVG name="Arrow" className="prevArrow" />
      </div>
    </PrevArrowStyled>
  )
}
