import React, { useState, useRef } from "react"
import styled from "styled-components"
import Slider from "../slider"
import SVG from "../svg"
import Img from "gatsby-image/withIEPolyfill"

const TimelineStyled = styled.div`
  padding: 0;
  position: relative;
  text-align: left;
  max-width: 2560px;
  height: 230px;
  width: 100%;
  margin: 0 auto 68px auto;

  .inner {
    margin: 0px 0;
  }

  .milestone {
    cursor: pointer;
    margin: 0 2%;
    position: relative;
    height: 234px;
    width: 250px;
    transition: margin 600ms;
    @media (max-width: 1450px) {
      margin-left: 50px;
    }
    @media (max-width: 768px) {
      margin-left: 50px;
    }
  }
  .year {
    position: absolute;
    font-size: 6em;
    font-family: "interstate-compressed";
    bottom: 90px;
    font-weight: bold;
    color: ${(p) => p.theme.Blue};
  }

  .blurb {
    position: absolute;
    font-size: 1.4em;
    line-height: 1.6em;
    top: 170px;
    width: 85%;
    min-width: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: ${(p) => p.theme.DarkBlue};
  }

  .image {
    position: absolute;
    height: 100px;
    width: 100%;
    bottom: 90px;
    padding-right: 0px;
    left: 90px;
  }

  .gatsby-image-wrapper {
    width: inherit;
  }

  .line {
    background-color: ${(p) => p.theme.DarkBlue};
    height: 4px;
    position: absolute;
    bottom: 80px;
    width: 100%;
  }

  .mileMarker {
    border-radius: 100%;
    height: 12px;
    width: 12px;
    background: ${(p) => p.theme.DarkBlue};
    border: 4px solid ${(p) => p.theme.DarkBlue};
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: height 200ms, width 200ms, background 200ms, bottom 200ms;
  }

  .slick-slide {
    &:first-child .mileMarker,
    &:last-child .mileMarker {
      background: ${(p) => p.theme.White};
      height: 30px;
      width: 30px;
      bottom: -14px;
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0px;
    text-align: center;
    max-width: 450px;
    width: 100%;
    margin: 0 auto;

    button:before {
      font-size: 40px;
      color: ${(p) => p.theme.DarkBlue};
    }

    ul {
      padding: 0;
    }
  }
`
const Timeline = ({ fields }) => {
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const { milestones } = fields
  const appendDots = (dots, sliderRef) => {
    return (
      <div className="dots">
        <ul>{dots}</ul>
        <p className="pagination">
          {currentSlide + 1}&nbsp;of&nbsp;{dots.length}
        </p>
      </div>
    )
  }

  return (
    <TimelineStyled>
      <div className="inner">
        <Slider
          settings={{
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 4000,
            slidesToShow: 4,
            dots: true,
            infinite: false,
            speed: 1000,
            afterChange: (next) => {
              setCurrentSlide(next)
            },
            appendDots: (dots) => appendDots(dots, sliderRef),
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
              {
                breakpoint: 4000,
                settings: {
                  slidesToShow: 7,
                },
              },
              {
                breakpoint: 1900,
                settings: {
                  slidesToShow: 6,
                },
              },
              {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 5,
                },
              },
              {
                breakpoint: 1350,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 1268,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 868,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 570,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          }}
        >
          {milestones.map((m, i) => {
            return (
              <div
                className={`milestone ${
                  currentSlide === i ? "activeMilestone" : ""
                }`}
                key={i}
              >
                <div className="year">{m.year}</div>
                <div className="blurb">{m.blurb}</div>
                <div className="image">
                  <Img
                    className="img"
                    fixed={m.image.localFile.childImageSharp.fixed}
                  />
                </div>
                <div className="line">
                  <div className="mileMarker" />
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </TimelineStyled>
  )
}

export default Timeline

const NextArrowStyled = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: -3px;
  right: 50%;
  margin-right: -35px;
  cursor: pointer;
  z-index: 3;

  .nextArrow {
    color: ${(p) => p.theme.DarkBlue};
    height: 20px;
  }

  @media (max-width: 768px) {
    display: none;
    margin-right: -30px;
    bottom: -3px;
  }
`

const NextArrow = ({ children, className, onClick }) => {
  return (
    <NextArrowStyled>
      <div className={className} onClick={onClick}>
        <SVG name="Arrow" className="nextArrow" />
      </div>
    </NextArrowStyled>
  )
}

const PrevArrowStyled = styled.div`
  color: ${(p) => p.theme.DarkBlue};
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: -3px;
  left: 50%;
  margin-left: -30px;
  cursor: pointer;
  z-index: 3;

  .prevArrow {
    color: ${(p) => p.theme.DarkBlue};
    height: 20px;
    transform: scaleX(-1);
  }

  @media (max-width: 768px) {
    display: none;
    left: 50%;
    right: auto;
    margin-left: -26px;
    bottom: -3px;
  }
`

const PrevArrow = ({ children, className, onClick }) => {
  return (
    <PrevArrowStyled onClick={onClick}>
      <div className={className}>
        <SVG name="Arrow" className="prevArrow" />
      </div>
    </PrevArrowStyled>
  )
}
